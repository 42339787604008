    <template>
  <div>
    <v-app>
      <v-main>
        <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
              <v-card class="elevation-12 pt-3">
                <div v-if="tokenVerified == null">
                  <h2>Please wait! Verifying...</h2>
                  <v-progress-circular
                    v-if="isLoading"
                    indeterminate
                    color="primary"
                    class="mb-2"
                    size="30"
                  ></v-progress-circular>
                </div>
                <div v-else-if="tokenVerified == false">
                  <h2>Sorry, couldn't verify! Redirecting to Login Page...</h2>
                </div>
                <div v-else>
                  <h2>Reset Password</h2>
                  <p>Please enter a new password for your account.</p>

                  <v-spacer></v-spacer>

                  <v-card-text>
                    <v-form v-model="resetForm">
                      <v-text-field
                        v-model="password"
                        label="New Password"
                        name="login"
                        @click:append="() => (typeValueCheck = !typeValueCheck)"
                        :append-icon="
                          typeValueCheck ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :type="typeValueCheck ? 'password' : 'text'"
                        :rules="[rules.pwRequired]"
                        outlined
                        dense
                        rounded
                      ></v-text-field>

                      <v-text-field
                        v-model="confirmPassword"
                        id="password"
                        label="Confirm Password"
                        :append-icon="
                          typeValueCheck ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        name="password"
                        :rules="[rules.pwRequired, rules.samePassword]"
                        @click:append="
                          () => (retypeValueCheck = !retypeValueCheck)
                        "
                        :type="retypeValueCheck ? 'password' : 'text'"
                        outlined
                        dense
                        rounded
                      ></v-text-field>
                      <v-btn :disabled="!resetForm || isLoading" @click="resetPassword" class="px-8 mb-3" color="primary"
                        >Change Password</v-btn
                      >
                    </v-form>
                  </v-card-text>
                </div>
                      <v-progress-circular v-if="isLoading" indeterminate size="30"></v-progress-circular>

              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
    <v-snackbar v-model="snackbar.visible">
      <span>{{ snackbar.text }}</span>
      <template>
        <v-btn color="pink" text @click="snackbar.visible = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import api from "@api/index";
import endpoints from "@api/repository";
export default {
  name: "ResetPassword",
  computed: {
    ...mapGetters(["accessToken", "currentUser", "isLoading"]),
    apiEssentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiErrors,
        setLoading: this.setLoading,
      };
    },
  },
  data() {
    return {
      resetForm: false,
      password: null,
      confirmPassword: null,
      typeValueCheck: true,
      retypeValueCheck: true,
      tokenVerified: null,
      snackbar: {
        visible: false,
        text: "",
        color: null,
      },
      rules: {
        pwRequired: (v) =>
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
            v
          ) ||
          "Password must contain - At least one Upper Case,One Digit, one AlphaNumeric Character",
        samePassword: (v) =>
          v == this.password || "Confirm Password must be same as New Password",
      },
    };
  },
  methods: {
    ...mapActions(['setLoading']),
    async verifyToken() {
      this.tokenVerified = await api.call(this.apiEssentials, endpoints.verifyResetPasswordToken, api.Methods.POST, {token: this.$route.query.token, id: this.$route.query.id})
      if (this.tokenVerified == false){
        setTimeout(() => this.$router.push({name: 'LoginUser'}), 2000);
      }
    },

    async resetPassword() {
        var response = await api.call(this.apiEssentials, endpoints.resetPassword, api.Methods.POST, {user_id: this.$route.query.id, token: this.$route.query.token, password: this.password});
        if(response){
          this.snackbar.text = "Password Reset Successful! Redirecting to Login Page...";
          this.snackbar.color = "green";
          this.snackbar.visible = true;
          setTimeout(() => this.$router.push({name: 'LoginUser'}), 2000);
        }
    },
    // handles api errors
    async handleApiErrors(err) {
      this.setLoading(false);
      if (err.response) {
        console.log(err.response);
        if(err.response.status == 409){
          this.snackbar.text = "Unauthorized";
          this.snackbar.color = "red";
          this.snackbar.visible = true;
          this.$router.push({name: 'LoginUser'});
        }
      } else {
        this.snackbar.text = "Unknown error occurred";
          this.snackbar.color = "red";
        this.snackbar.visible = true;

      }
    },
  },
  created() {
    this.verifyToken();
  }
};
</script>
<style scoped>
.img-form {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.button-form {
  display: block;
  text-align: center;
  margin: 0 auto;
}
</style>